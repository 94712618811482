import { INavigationLink, NavigationPath } from '../types/navgiation';

export const navigationLinks: INavigationLink[] = [
  {
    text: 'Home',
    to: NavigationPath.HOME,
  },
  {
    text: 'Photos',
    to: NavigationPath.PHOTOS,
  },
  {
    text: 'Reservieren',
    to: NavigationPath.RESERVE,
  },
  {
    text: 'Impressum',
    to: NavigationPath.IMPRESSUM,
  },
];
