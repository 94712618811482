export interface INavigationLink {
  text: string;
  to: string;
}

export enum NavigationPath {
  HOME = '/',
  PHOTOS = '/photos',
  FEATURES = '/ausstattung',
  RESERVE = '/reservieren',
  IMPRESSUM = '/impressum',
}
