const theme = {
  bg: {
    primary: '#fff',
    secondary: '#F7FBFF',
    lightBlue: '#E5F2FF',
  },
  text: {
    primary: '#4A4A4A',
    secondary: '#525560',
    tertiary: '#9B9B9B',
  },
  border: {
    primary: '#DDDDDD',
    secondary: '#EEEEEE',
  },
  colors: {
    blue: '#479CFF',
    // blue: '#006AE5',
    darkBlue: '#003F88',
    orange: '#F5A623',
    grey: '#858585',
  },
  buttons: {
    blueHover: '#4091EE',
    whiteHover: '#F7FBFF',
  },
  contentWidth: '1200px',
};

export default theme;
