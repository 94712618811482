import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { navigationLinks } from '../data/navigation';

const FooterContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid ${props => props.theme.border.secondary};
  background-color: ${props => props.theme.bg.primary};
`;

const FooterGrid = styled.div`
  max-width: ${props => props.theme.contentWidth};
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 40px 40px;
  align-items: center;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: ${props => props.theme.text.primary};
  }

  span {
    font-weight: 600;
    margin: 0;
    font-size: 1rem;
  }

  .bold {
    font-weight: 600;
  }

  ul {
    /* display: flex; */
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 10px 10px 0 0px;

      a {
        color: ${props => props.theme.text.secondary};

        &:hover {
          color: ${props => props.theme.text.primary};
        }
      }
    }
  }

  @media (min-width: 600px) {
  }
`;

const Footer = () => {
  const linksList = (
    <ul style={{ padding: 16, fontSize: 16 }}>
      {navigationLinks.map(({ to, text }, i) => (
        <li key={i}>
          <Link to={to}>{text}</Link>
        </li>
      ))}
    </ul>
  );

  return (
    <FooterContainer>
      <FooterGrid>{linksList}</FooterGrid>
    </FooterContainer>
  );
};

export default Footer;
